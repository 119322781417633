import Vue from 'vue'
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router'

// TODO: finger out type of originalPush
const originalPush = (VueRouter.prototype.push as unknown) as Promise<Route>
VueRouter.prototype.push = function push(location: RawLocation) {
  return (originalPush as any).call(this, location).catch((err: Error) => err)
}

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "homeWrap" */ '../views/HomeWrap.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: '/solution',
        name: 'Solution',
        component: () =>
          import(/* webpackChunkName: "solution" */ '../views/About.vue')
      },
      {
        path: '/domestic-electronics',
        name: 'DomesticElectronics',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/shop',
        name: 'Shop',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/overallPlan',
        name: 'OverallPlan',
        component: () =>
          import(/* webpackChunkName: "overallPlan" */ '../views/OverallPlan.vue')
      },  
      {
        path: '/importElec',
        name: 'ImportElec',
        component: () =>
          import(/* webpackChunkName: "importElec" */ '../views/ImportElec.vue')
      }, 
      {
        path: '/suspended',
        name: 'Suspended',
        component: () =>
          import(/* webpackChunkName: "suspended" */ '../views/Suspended.vue')
      },     
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () =>
          import(/* webpackChunkName: "aboutUs" */ '../views/AboutUs.vue')
      },
      {
        path: '/disclaimers',
        name: 'Disclaimers',
        component: () =>
          import(/* webpackChunkName: "disclaimers" */ '../views/Disclaimers.vue')
      },
      {
        path: '/privacyPolicy',
        name: 'PrivacyPolicy',
        component: () =>
          import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue')
      },
      {
        path: '/askPriceDesc',
        name: 'AskPriceDesc',
        component: () =>
          import(/* webpackChunkName: "askPriceDesc" */ '../views/AskPriceDesc.vue')
      },
      {
        path: '/logisticsDesc',
        name: 'LogisticsDesc',
        component: () =>
          import(/* webpackChunkName: "logisticsDesc" */ '../views/LogisticsDesc.vue')
      },
      {
        path: '/invoiceDesc',
        name: 'InvoiceDesc',
        component: () =>
          import(/* webpackChunkName: "invoiceDesc" */ '../views/InvoiceDesc.vue')
      },
      {
        path: '/wxPayDesc',
        name: 'WxPayDesc',
        component: () =>
          import(/* webpackChunkName: "wxPayDesc" */ '../views/WxPayDesc.vue')
      },
      {
        path: '/aliPayDesc',
        name: 'AliPayDesc',
        component: () =>
          import(/* webpackChunkName: "aliPayDesc" */ '../views/AliPayDesc.vue')
      },
      {
        path: '/unionPayDesc',
        name: 'UnionPayDesc',
        component: () =>
          import(/* webpackChunkName: "unionPayDesc" */ '../views/UnionPayDesc.vue')
      },
      {
        path: '/returnExchangeDesc',
        name: 'ReturnExchangeDesc',
        component: () =>
          import(/* webpackChunkName: "returnExchangeDesc" */ '../views/ReturnExchangeDesc.vue')
      },
      {
        path: '/artificialServices',
        name: 'ArtificialServices',
        component: () =>
          import(/* webpackChunkName: "artificialServices" */ '../views/ArtificialServices.vue')
      },
      {
        path: '/commonProblem',
        name: 'CommonProblem',
        component: () =>
          import(/* webpackChunkName: "commonProblem" */ '../views/CommonProblem.vue')
      },
      {
        path: '/connectUs',
        name: 'ConnectUs',
        component: () =>
          import(/* webpackChunkName: "connectUs" */ '../views/ConnectUs.vue')
      },
      {
        path: '/wxPay',
        name: 'WxPay',
        component: () =>
          import(/* webpackChunkName: "wxPay" */ '../views/WxPay.vue'),
          meta: {
            title: '微信支付 - 嗨芯网-heyelec.com',
            name: '微信支付',
            requireAuth: true
          }
      },
      {
        path: '/webAgreement',
        name: 'WebAgreement',
        component: () =>
          import(/* webpackChunkName: "WebAgreement" */ '../views/WebAgreement.vue')
      },
      {
        path: '/news',
        name: 'News',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/News.vue')
      },
      {
        path: '/searchResult/:keyword',
        name: 'SearchResult',
        component: () =>
          import(/* webpackChunkName: "searchResult" */ '../views/SearchResult.vue')
      },
      {
        path: '/productDetail/:id',
        name: 'ProductDetail',
        component: () =>
          import(/* webpackChunkName: "productDetail" */ '../views/ProductDetail.vue')
      },
      {
        path: '/search/:ts',
        name: 'Search',
        component: () =>
          import(/* webpackChunkName: "search" */ '../views/Search.vue')
      },
      // {
      //   path: '/news1',
      //   name: 'News',
      //   component: () =>
      //     import(/* webpackChunkName: "about" */ '../views/About.vue')
      // },
      {
        path: '/cart',
        name: 'Cart',
        component: () =>
          import(/* webpackChunkName: "cart" */ '../views/Cart.vue'),
        meta: {
          title: '购物车 - 嗨芯网-heyelec.com',
          name: '购物车',
          requireAuth: true
        }
      },
      {
        path: '/confirm-order',
        name: 'ConfirmOrder',
        component: () =>
          import(/* webpackChunkName: "confirmOrder" */ '../views/ConfirmOrder.vue'),
        meta: {
        title: '订单结算 - 嗨芯网-heyelec.com',
        name: '订单结算',
        requireAuth: true
        }
      },
      {
        path: '/pageNotFound',
        name: 'PageNotFound',
        component: () =>
          import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFound.vue')
      },

      // 会员中心
      {
        path: '/member',
        component: () => import(/* webpackChunkName: "member" */ '../views/member/index.vue'),
        children: [
          {
            path: '',
            name: 'UserInfo',
            component: () => import(/* webpackChunkName: "userInfo" */ '../views/member/UserInfo.vue'),
            meta: {
              title: '会员中心 - 嗨芯网-heyelec.com',
              name: '个人资料',
              requireAuth: true
            }
          },
          {
            path: '/identify',
            name: 'Identify',
            component: () => import(/* webpackChunkName: "identify" */ '../views/member/Identify.vue'),
            meta: {
              title: '认证会员 - 嗨芯网-heyelec.com',
              name: '认证会员',
              requireAuth: true
            }
          },
          {
            path: '/account',
            name: 'Account',
            component: () => import(/* webpackChunkName: "account" */ '../views/member/Account.vue'),
            meta: {
              title: '账户管理 - 嗨芯网-heyelec.com',
              name: '账户管理',
              requireAuth: true
            }
          },
          {
            path: '/order',
            name: 'Order',
            component: () => import(/* webpackChunkName: "order" */ '../views/member/Order.vue'),
            meta: {
              title: '全部订单 - 嗨芯网-heyelec.com',
              name: '全部订单',
              requireAuth: true
            }
          },
          {
            path: '/address',
            name: 'Address',
            component: () => import(/* webpackChunkName: "address" */ '../views/member/Address.vue'),
            meta: {
              title: '收货地址 - 嗨芯网-heyelec.com',
              name: '收货地址',
              requireAuth: true
            }
          },
          {
            path: '/invoice',
            name: 'Invoice',
            component: () => import(/* webpackChunkName: "invoice" */ '../views/member/Invoice.vue'),
            meta: {
              title: '增值税专用发票 - 嗨芯网-heyelec.com',
              name: '增值税专用发票',
              requireAuth: true
            }
          },
          {
            path: '/normalInvoice',
            name: 'NormalInvoice',
            component: () => import(/* webpackChunkName: "normalInvoice" */ '../views/member/NormalInvoice.vue'),
            meta: {
              title: '增值税普通发票 - 嗨芯网-heyelec.com',
              name: '增值税普通发票',
              requireAuth: true
            }
          },
          {
            path: '/invoiceHistory',
            name: 'InvoiceHistory',
            component: () => import(/* webpackChunkName: "invoiceHistory" */ '../views/member/InvoiceHistory.vue'),
            meta: {
              title: '历史发票查询 - 嗨芯网-heyelec.com',
              name: '历史发票查询',
              requireAuth: true
            }
          },
          {
            path: '/memberService',
            name: 'MemberService',
            component: () => import(/* webpackChunkName: "memberService" */ '../views/member/MemberService.vue'),
            meta: {
              title: '售后服务 - 嗨芯网-heyelec.com',
              name: '售后服务',
              requireAuth: true
            }
          },
          
        ]
      }
    ]
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/register-international',
    name: 'Register-International',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/RegisterV2.vue')
  },
  {
    path: '/login-international',
    name: 'Login-International',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginV2.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '*',
    redirect: '/pageNotFound'
  }
]

const router = new VueRouter({
  routes,
  mode:'hash'
})

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
router.beforeEach((to, from, next) => {
  // const isLogined = !!window.localStorage.getItem('token')
   /* 路由发生变化修改页面title */
  //  if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  const isLogined = window.localStorage.getItem('Authorization')
  // console.log(isLogined,"============")
  if (to.matched.some(item => item.meta.requireAuth) && !isLogined) {
      router.push('/login')
  } else {
      next()
  }
  
})
 

export default router
