import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from 'element-ui'

//import 'element-ui/lib/theme-chalk/index.css'
//import '@/styles/element.custom.styl'
import '@/styles/element.custom.scss'

import '@/styles/font/iconfont.css'
import '@/styles/global.styl'
import '@/styles/reset.styl'
import '@/styles/header.styl'
import '@/styles/footer.styl'
import '@/styles/sidebar.styl'





Vue.use(Element)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
