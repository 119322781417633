
























































































import $ from "jquery";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
salerQq = window.localStorage.getItem("salerQq");
            
  mounted():void {
    $(window).scroll(function () {
      if ($(window).scrollTop() > 100) {
        $("#float_bar").fadeIn(1500);
      } else {
        $("#float_bar").fadeOut(1500);
      }
    });

    //当点击跳转链接后，回到页面顶部位置

    $("#to_top").click(function () {
      $("body,html").animate({ scrollTop: 0 }, 1000);
      return false;
    });
  }
}
