








import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/Sidebar.vue";
@Component({
  components: {
    Sidebar,
  },
})
export default class App extends Vue {}
